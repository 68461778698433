import { Elm } from 'Regulations/Index'

document.addEventListener('DOMContentLoaded', () => {
    const node = document.getElementById('Regulations.Index')
    const flags = JSON.parse(node.getAttribute('data'))

    let app = Elm.Regulations.Index.init({
        node: node,
        flags: flags
    })

    window.addEventListener('popstate', function () {
        app.ports.onUrlChange.send(location.href)
    })

    app.ports.pushUrl.subscribe(function (url) {
        history.pushState({}, '', url)
    })
})